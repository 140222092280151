import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import Switch from "react-switch";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";
// import IconLocal from "../../client_customizations/assets/images/icons/icon-local.png";
import IconBEV from "../../assets/images/icons/icon-electric.png";
import IconPHEV from "../../assets/images/icons/icon-hybrid.png";
import SelectVehicleAgeFilter from "../../../components/InputComponents/SelectVehicleAgeFilter/SelectVehicleAgeFilter";

import { FormattedMessage, useIntl } from "react-intl";

const EVFilterControls = ({ vehicles, usedEvs }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  // const locallyAvailable = userPrefs.get("locallyAvailableVehicleFilter");
  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");
  const fuelTypeFilterData = userPrefs.get("vehicleFuelTypeFilters");

  const vehicleTypes = {
    Sedan: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.sedan",
          defaultMessage: "Sedan",
        })
      : "Sedan",
    Hatchback: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.hatchback",
          defaultMessage: "Hatchback",
        })
      : "Hatchback",
    Coupe: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.coupe",
          defaultMessage: "Coupe",
        })
      : "Coupe",
    Crossover: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.crossover",
          defaultMessage: "Crossover",
        })
      : "Crossover",
    Minivan: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.minivan",
          defaultMessage: "Minivan",
        })
      : "Minivan",
    SUV: intl.formatMessage
      ? intl.formatMessage({ id: "vehicle.type.suv", defaultMessage: "SUV" })
      : "SUV",
    Wagon: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.wagon",
          defaultMessage: "Wagon",
        })
      : "Wagon",
    Pickup_truck: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.truck",
          defaultMessage: "Truck",
        })
      : "Truck",
  };

  // const renderLocallyAvailableFilter = (
  //   <button
  //     className={locallyAvailable ? "btn active" : "btn"}
  //     type="button"
  //     onClick={() =>
  //       userPrefs.set({ locallyAvailableVehicleFilter: !locallyAvailable })
  //     }
  //   >
  //     <span className="badge">
  //       <img src={IconLocal} alt="" />
  //     </span>
  //     Local Vehicles Only
  //   </button>
  // );

  const updateFormFactorFilter = (key) => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];
    userPrefs.set({
      vehicleFormFactorFilters: newFilters,
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === "suv"
          ? "SUV"
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;
      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn active" : "btn"}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          {vehicleTypes[filterName]}
        </button>
      );
    }
  );

  const updateFuelTypeFilter = (checked, event, selectedKey) => {
    const newFilters = Object.keys(fuelTypeFilterData).reduce(function (
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters,
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let filterIconSrc = "";
      switch (filterKey) {
        case "bev":
          filterName = intl.formatMessage
            ? intl.formatMessage({
                id: "vehicle.allElectric",
                defaultMessage: "All Electric",
              })
            : "All Electric";
          filterIconSrc = IconBEV;
          break;
        case "phev":
          filterName = intl.formatMessage
            ? intl.formatMessage({
                id: "vehicle.plugInHybrid",
                defaultMessage: "Plug in Hybrid",
              })
            : "Plug in Hybrid";
          filterIconSrc = IconPHEV;
          break;
        default:
      }

      return (
        <div key={i} className="filter-switch">
          <span className="badge">
            <img src={filterIconSrc} alt="" />
          </span>
          <label htmlFor={filterKey} className="name">{filterName}</label>
          <div className="switch">
            <Switch
              id={filterKey}
              checked={fuelTypeFilterData[filterKey] ? true : false}
              onChange={updateFuelTypeFilter}
              onColor="#0056B8"
              offColor="#333333"
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <div className="clearfix"></div>
        </div>
      );
    }
  );

  return (
    <div className="input-well filter-controls block-box-shadow">
      <p className="h2">
        <FormattedMessage
          id="evfilter"
          defaultMessage="Filter"
          description="Filter Title"
        />
      </p>
      <form>
        {/* <div className="form-group">
          <span className="label-style">
            Availability
            <ToolTip
              message={`These vehicles have been seen in local  ${process.env.REACT_APP_COMPANY_REGION}  area dealer inventory in the recent past`}
              id="local_availability_tooltip"
            />
          </span>
          <div className="btn-block-container">
            {renderLocallyAvailableFilter}
          </div>
        </div> */}
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.fuel"
              defaultMessage="Fuel"
              description="Fuel"
            />
            <ToolTip
              message={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "evfilter.fuelTooltip",
                      defaultMessage:
                        "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
                    })
                  : "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline."
              }
              id="fuel_tooltip"
            />
          </span>
          <div className="btn-block-container">{renderedFuelTypeFilters}</div>
        </div>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.type"
              defaultMessage="Type"
              description="Type"
            />
          </span>
          <div className="btn-grid-container">{renderedFormFactorFilters}</div>
        </div>
        {usedEvs ? (
          <div className="form-group">
            <SelectVehicleAgeFilter />
          </div>
        ) : null}
      </form>
      <p className="legal-disclaimer">
        <FormattedMessage
          id="evs.disclaimer"
          defaultMessage="Vehicles displayed may not reflect actual availability. We do not endorse or recommend any specific vehicle or car manufacturer."
          description="Vehicles Disclaimer"
        />
      </p>
    </div>
  );
};

export default EVFilterControls;
