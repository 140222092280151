import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";

import EVCatalog from "./../../components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

import EVFilterControls from "./../../client_customizations/components/EVFilterControls/EVFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import { FormattedMessage, useIntl } from "react-intl";
import ToolTip from "../../components/shared/ToolTip/ToolTip";

const EVs = ({ electricVehicles, ip, uuid, zipcode }) => {
  const intl = useIntl();

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <MatchScoreOptions zipcode={zipcode} />
      <br />
      <EVFilterControls vehicles={electricVehicles} />
      <br />
      <p className="ev-disclaimer">
        <FormattedMessage
          id="ev.disclaimer"
          defaultMessage="El Paso Electric (EPE) does not endorse, recommend, or promote any
              vehicle (electrified or otherwise) or dealership over another."
          description="Ev disclaimer"
        />
        <ToolTip
          customClass="smallText"
          message={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "tooltip.disclaimer",
                  defaultMessage:
                    "Site visitors are encouraged to perform their own due diligence when selecting a vehicle, home charging station, or service provider. Please consult the vehicle’s manufacturer, incentive grantor, dealership, or public charging station operator with any specific questions or inquiries for that particular product or service. Vehicle specifications such as, but not limited to quotes of range and time to charge, are estimates and individual users’ experiences may vary. The vehicle pricing and specifications, incentive information, home charger details, and calculations in this tool are for educational purposes only and EPE does not guarantee the accuracy or completeness of any of the information provided. EPE rebates are subject to change and visitors should verify EPE’s rates for their particular service territory at https://www.epelectric.com/customers/rates-and-regulations.",
                })
              : "Site visitors are encouraged to perform their own due diligence when selecting a vehicle, home charging station, or service provider. Please consult the vehicle’s manufacturer, incentive grantor, dealership, or public charging station operator with any specific questions or inquiries for that particular product or service. Vehicle specifications such as, but not limited to quotes of range and time to charge, are estimates and individual users’ experiences may vary. The vehicle pricing and specifications, incentive information, home charger details, and calculations in this tool are for educational purposes only and EPE does not guarantee the accuracy or completeness of any of the information provided. EPE rebates are subject to change and visitors should verify EPE’s rates for their particular service territory at https://www.epelectric.com/customers/rates-and-regulations."
          }
          id="ev_disclaimer_tooltip"
        />
      </p>
    </>
  );

  return (
    <section id="EVs">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center main-title">
            <h2>
              <FormattedMessage
                id="electricVehicles"
                defaultMessage="Electric Vehicles"
                description="Electric Vehicles"
              />
            </h2>
            <div className="contain-text">
              <p className="lead">
                <FormattedMessage
                  id="evs.welcomeSub"
                  defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
                  description="Description of Colton Recharged"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent
              buttonText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "evs.buttonMatchScoreAndFilters",
                      defaultMessage: "Match Score and Filters",
                    })
                  : "Match Score and Filters"
              }
              titleText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "evs.matchScoreAndFilters",
                      defaultMessage: "Refine Match Score and Filters",
                    })
                  : "Refine Match Score and Filters"
              }
            >
              {renderOptions}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderOptions}
          </div>
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>
              <FormattedMessage
                id="results.updated"
                defaultMessage="Results have been updated as of "
                description="Results have been updated as of "
              />
              {time}.
            </span>
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            <div className="row">
              <div className="col">
                <div className="pull-right">
                  <EvSortControls />
                </div>
              </div>
            </div>
            <EVCatalog
              vehicles={electricVehicles}
              hasEvDetails
              hasIncentivesAndMatchScore
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
