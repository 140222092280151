import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../client_customizations/assets/images/icons/discover-evs-icon.svg";
import DISCOVER_INCENTIVES from "../../client_customizations/assets/images/icons/maximize-incentives-icon.svg";
import LOCATE_CHARGING_STATIONS from "../../client_customizations/assets/images/icons/locate-charging-stations.svg";
// import EV_FACTS from "../../client_customizations/assets/images/icons/ev-facts.svg";
import FIND_DEALERS from "../../client_customizations/assets/images/icons/find-dealers.svg";
import HOME_CHARGERS from "../../client_customizations/assets/images/icons/home-chargers.svg";
import COMPARE_VEHICLES from "../../client_customizations/assets/images/icons/compare-vehicles-icon.svg";
import { Link } from "react-router-dom";
import SmoothScroll from "./../../utils/Helpers/SmoothScroll";
import { FormattedMessage } from "react-intl";

const LinkCard = ({ type }) => {
  let src, link, scrollTo, title;

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      scrollTo = "HomepageVehiclesCarousel";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="BROWSE ELECTRIC {lineBreak} VEHICLES"
            description="HomePage Linkcard Browse Electric Vehicles"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "DISCOVER_INCENTIVES":
      src = DISCOVER_INCENTIVES;
      scrollTo = "HomepageIncentives";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="DISCOVER {lineBreak} INCENTIVES"
            description="HomePage Linkcard Discover Incentives"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      scrollTo = "HomepageChargingStations";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="LOCATE CHARGING {lineBreak} STATIONS"
            description="HomePage Linkcard Locate Charging Stations"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "FIND_DEALERS":
      src = FIND_DEALERS;
      link = "/dealers";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.findDealers"
            defaultMessage="Find {lineBreak} dealers"
            description="Find Dealers Title"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "HOME_CHARGERS":
      src = HOME_CHARGERS;
      link = "/home-chargers";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.homeChargers"
            defaultMessage="HOME {lineBreak} CHARGERS"
            description="HomePage Linkcard Home Chargers"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "COMPARE_VEHICLES":
      src = COMPARE_VEHICLES;
      link = "/compare-vehicles";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.compare"
            defaultMessage="COMPARE VEHICLES"
            description="HomePage Linkcard Home Chargers"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    default:
      return null;
  }

  return scrollTo ? (
    <div className="link-card">
      <div className="evc-card" onClick={(e) => SmoothScroll(scrollTo)}>
        <div className="full-width">
          <img src={src} alt="" />
          <p className="h6">{title}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <div className="full-width">
          <img src={src} alt="" />
          <p className="h6">{title}</p>
        </div>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string,
};
