import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { TabContent, TabPane } from "reactstrap";
import Uuid from "../../../utils/Uuid/Uuid"
import calcSalesTax from "../../../functions/financial/calcSalesTax";
import calcResaleFactor from "../../../functions/financial/calcResaleFactor";

import {
  FormatCarName,
  FormatAsDollars,
  FormatAsCentsDetailed,
  FormatAsCents,
  FormatAsThousands,
  FormatAsPercent,
  FormatAsPercentRounded
} from "../../../utils/Helpers/Format";
import {
  VehicleCost,
  ElectricityCost,
  MaintenanceCost,
  InsuranceCost
} from "../../../functions/vehicle/CostOfOwnership/calcs/index";
import {
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
  AVERAGE_MAINTENANCE_COST_PER_MILE,
  AVERAGE_MSRP,
  ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR,
  AVERAGE_INSURANCE_COST_ANNUALLY
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"

import { MUNICIPALITY_INFO } from "../../../data/assumptions/MUNICIPALITY"
import getCarCostOfOwnership from "../../../functions/vehicle/CostOfOwnership/getCarCostOfOwnership";
import { FormattedMessage, useIntl } from 'react-intl';

import "./CalcPanels.scss";

const CalcPanels = ({ cars, insuranceData, maintenanceData }) => {
  const intl = useIntl() 
  const userPrefs = useContext(UserPrefsContext);
  const [activeTab, setTab] = useState("1");
  const toggle = tab => setTab(tab);
  const purchaseMethod = userPrefs.get("purchaseMethod");
  const monthsOfOwnership = userPrefs.get("monthsOfOwnership");
  const annualMiles = userPrefs.get("milesDrivenAnnually");
  const interestRateAsBasisPoints = userPrefs.get("interestRateAsBasisPoints");
  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const zipcode = userPrefs.get("zipcode");
  const costPublicCharging = process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING ? userPrefs.get("costPublicCharging") : 0
  const publicChargingPercentage = process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING ? userPrefs.get("publicChargingPercentage") : 0
  const electricityRate = process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE ? userPrefs.get("electricityRate") : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value
  const gasolinePrice = userPrefs.get("gasolinePriceInCentsPerGal")

  const fuelTypeForCar = car => car.fuel ? car.fuel : ""

  const isTherePHEV = cars.find(car => ["PHEV"].includes(fuelTypeForCar(car).toUpperCase()));

  const isItGasVehicle = car => fuelTypeForCar(car) === "gas" || fuelTypeForCar(car) === ""

  const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;

  const translatePurchaseMethod = {
    cash : <FormattedMessage 
      id="ev.cash"
      defaultMessage="Cash"
      description="Cash"
    />,
    loan : <FormattedMessage 
      id="ev.loan"
      defaultMessage="Loan"
      description="Loan"
    />,
    lease : <FormattedMessage 
      id="ev.lease"
      defaultMessage="Lease"
      description="Lease"
    />,
  }

  const carsCosts = car => {
    return getCarCostOfOwnership(
      car, 
      userPrefs,
      insuranceData,
      maintenanceData
    )
  }

  let renderPHEVFuelSplitAdjustment = fuelType => {
    if (!["BEV", "PHEV"].includes(fuelType.toUpperCase())) return "0%";
    return ["PHEV"].includes(fuelType.toUpperCase())
      ? FormatAsPercentRounded(electricMilesPortionForPhev)
      : "100%";
  };

  const renderPHEVFuelSplitCars = cars.map(car => {
    return <td key={Uuid()}
    >{renderPHEVFuelSplitAdjustment(fuelTypeForCar(car))}</td>
  })

  let renderPHEVFuelSplitAdjustmentRow = isTherePHEV ? (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="ev.cardetails.calc.portionElectric"
          defaultMessage="Portion of Electric Driving"
          description="Portion of Electric Driving"
        />
      </th>
        {renderPHEVFuelSplitCars}
      <td>
        {process.env.REACT_APP_METRIC_SYSTEM ? <FormattedMessage 
          id="ev.showSources.portionElectricSource"
          defaultMessage="User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."
          description="Portion Electric Source"
        /> : <FormattedMessage
            id="ev.showSources.portionElectricSourceKilometer"
            defaultMessage="User input. For a hybrid vehicle, estimates the portion of kilometers driven using the electric battery instead of gasoline."
          description="Portion Electric Source"
        />}
      </td>
    </tr>
  ) : null;

  let renderResaleValueCars = cars.map(car => {
    return <td key={Uuid()}>
      {FormatAsDollars(VehicleCost.resaleValue(car, lifetimeMiles, purchaseMethod))}
    </td>
  })

  let resaleValueRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="ev.showSources.resaleValue"
          defaultMessage="Resale Value"
          description="Resale Value"
        />
      </th>
        {renderResaleValueCars}
        <td>
        {cars.map(car => {
          return (
            <div className="resale-vehicle-source" key={Uuid()}>
              <FormattedMessage 
                id="ev.showSources.resaleValueSource"
                defaultMessage="e^(ln(.6)/36000*lifetimeMiles)*MSRP"
                description="Compare Vehicle Text"
                values= {{
                  lifetimeMiles: FormatAsThousands(lifetimeMiles),
                  resaleFactor: calcResaleFactor(purchaseMethod, car),
                  carName: car.model
                }}
              />
            </div>
          )
        })}
        </td>
    </tr>
  );

  let renderCarNames = cars.map(car => {
    return <th scope="col" key={Uuid()}>
      {FormatCarName(car)}
    </th>
  })

  let renderCarMSRP = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(car.msrp)}</td>
  })

  const renderCarSalesTaxDollars = cars.map(car => {
    const msrpWithTax = calcSalesTax(car.msrp, userPrefs.get("salesTax"));
    const salesTax = msrpWithTax - car.msrp;

    return <td key={Uuid()}>{FormatAsDollars(salesTax)}</td>
  })

  let renderCarIncentives = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(VehicleCost.incentives(car, purchaseMethod))}</td>
  })

  let renderTotalCarCost = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(carsCosts(car).vehicle.total)}</td>
  })

  let renderCarDownPayment = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(VehicleCost.downPayment(car, userPrefs.get("salesTax")))}</td>
  })

  let renderCarLoanAmount = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(VehicleCost.loanAmountToBeFinanced(car, userPrefs.get("salesTax")))}
      </td>
    )
  })

  let renderCarMonthlyPayments = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(
          VehicleCost.monthlyLoanPayment(
            car,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            userPrefs.get("salesTax")
          )
        )}
      </td>
    )
  })
  
  let renderCarTotalMonthlyPayments = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(
          VehicleCost.monthlyLoanPaymentsTotal(
            car,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            userPrefs.get("salesTax")
          )
        )}
      </td>
    )
  })

  let renderCarCapitalizedCost = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(VehicleCost.leaseCapitalizedCost(car, userPrefs.get("salesTax")))}</td>
  })

  let renderCarMonthlyDepreciation = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(
          VehicleCost.monthlyDepreciationCost(
            car,
            lifetimeMiles,
            monthsOfOwnership,
            userPrefs.get("salesTax")
          )
        )}
      </td>
    )
  })

  let renderCarMonthlyFinancingCost = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(
          VehicleCost.monthlyFinancingCost(
            car,
            lifetimeMiles,
            interestRateAsBasisPoints,
            userPrefs.get("salesTax")
          )
        )}
      </td>
    )
  })

  let renderCarMonthlyLeasePayments = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(
          VehicleCost.monthlyLeasePayment(
            car,
            annualMiles,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            userPrefs.get("salesTax")
          )
        )}
      </td>
    )
  })

  let renderCarFirstLeasePayment = cars.map(car => {
    return  (
      <td key={Uuid()}>
        {FormatAsDollars(
          VehicleCost.firstLeasePayment(
            car,
            annualMiles,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            userPrefs.get("salesTax")
          )
        )}
      </td>
    )
  })

  let renderCarNonCapitalizedLeaseIncentives = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(VehicleCost.nonCapitalizedLeaseIncentives(car))}</td>
  })

  let renderElectricityRate = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) ? "N/A" : `$${Number(electricityRate).toFixed(4)}/kWh`}</td>
  })

  let renderKWHPerHundredMiles = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) ? "N/A" : car.electric_efficiency + " kWh/100 " + (process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.milesShort", defaultMessage: "mi" }))}</td>
  })

  let renderDollarPerHundredMiles = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) ? "N/A" : FormatAsCents(ElectricityCost.perHundredElectricMiles(car, electricityRate, costPublicCharging, publicChargingPercentage))}</td>
  })

  let renderEstimatedCostOfPublicCharging = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) ? "N/A" : `$${costPublicCharging}/kWh`}</td>
  })

  let renderMixPublicPrivateCharging = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) ? "N/A" : `${publicChargingPercentage}% Public`}</td>
  })

  let renderTotalElectricityCost = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) ? "$0" : FormatAsDollars(carsCosts(car).electricity.total)}</td>
  })

  let renderGasPrice = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) || car.fuel === "PHEV" ? FormatAsCents(gasolinePrice / 100) : "N/A"}</td>
  })

  let renderMPG = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) || car.fuel === "PHEV" ? car.fossil_fuel_efficiency + (process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.cardetails.calc.lpkm", defaultMessage: " liters/kilometer" }) : intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"})) : "N/A"}</td>
  })

  let renderGasolineCost = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(carsCosts(car).gasoline.total)}</td>
  })

  let renderMaintenanceForGas = cars.map(car => {
    return <td key={Uuid()}>{isItGasVehicle(car) || car.fuel === "PHEV" ? FormatAsCentsDetailed(MaintenanceCost.maintenanceCostPerGasMile(car)) : "N/A"}</td>
  })

  let renderEVMaintenance = cars.map(car => {
    return <td key={Uuid()}>{car.fuel === "PHEV" ? ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.valueAsFormatted : "N/A"}</td>
  })

  let renderMaintenanceForElectricity = cars.map(car => {
    return <td key={Uuid()}>{car.fuel === "PHEV" ? FormatAsCentsDetailed(
      MaintenanceCost.maintenanceCostPerElectricMile(car)
    ) : "N/A"}</td>
  })

  let renderBlendedMaintenance = cars.map(car => {
    return <td key={Uuid()}>{FormatAsCentsDetailed(carsCosts(car).maintenance.perMile)}</td>
  })

  let renderTotalMaintenance = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(carsCosts(car).maintenance.total)}</td>
  })

  let renderInsurance = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(InsuranceCost.perYear(car, userPrefs.get("municipality"), insuranceData))}</td>
  })

  let renderTotalInsurance = cars.map(car => {
    return <td key={Uuid()}>{FormatAsDollars(carsCosts(car).insurance.total)}</td>
  })

  let renderTableHead = (
    <thead>
      <tr>
        <th scope="col">
          <FormattedMessage 
            id="description"
            defaultMessage="Description"
            description="Description"
          />
        </th>
        {renderCarNames}
        <th scope="col">
          <FormattedMessage 
            id="source"
            defaultMessage="Source"
            description="Source"
          />
        </th>
      </tr>
    </thead>
  )

  let renderPurchaseMethodRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="ev.purchaseMethod"
          defaultMessage="Purchase Method"
          description="Purchase Method"
        />
      </th>
      <td colSpan={cars.length}>
        {/* {purchaseMethod.charAt(0).toUpperCase() + purchaseMethod.slice(1)} */}
        {translatePurchaseMethod[purchaseMethod]}
      </td>
      <td>
        <FormattedMessage 
          id="ev.cardetails.calc.userInput"
          defaultMessage="User Input"
          description="User Input"
        />
      </td>
    </tr>
  )

  let renderMSRPRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="vehicle.msrp"
          defaultMessage="MSRP"
          description="MSRP"
        />
      </th>
      {renderCarMSRP}
      <td />
    </tr>
  )

  let renderSalesTaxRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="assumption.salesTaxPercent"
          defaultMessage="Sales Tax"
          description="Sales Tax"
        />
      </th>
      <td colSpan={cars.length}>{`${(userPrefs.get("salesTax") * 100).toFixed(2)}%`}</td>
      <td>
        {process.env.REACT_APP_SLIDE_SALES_TAX ? 
          <FormattedMessage 
            id="ev.cardetails.calc.userInput"
            defaultMessage="User Input"
            description="User Input"
          />
          :
          <a href={zipcode === "88001" ? "https://www.mvd.newmexico.gov/chapter-6-fees-penalties-and-excise-tax/" : "https://www.sale-tax.com/Texas"}>{zipcode === "88001" ? 
              <FormattedMessage 
                id="ev.cardetails.calc.taxRate-NM"
                defaultMessage="NM Sales Tax Rate"
                description="NM Sales Tax Rate"
              /> : 
              <FormattedMessage 
                id="ev.cardetails.calc.taxRate"
                defaultMessage="TX Sales Tax Rate"
                description="TX Sales Tax Rate"
              />
            }
          </a>
        }
      </td>
    </tr>
  )

  const renderSalesTaxDollarsRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="assumption.salesTax"
          defaultMessage="sales tax $"
          description="sales tax $"
        />
      </th>
      {renderCarSalesTaxDollars}
      <td />
    </tr>
  )

  let renderIncentivesRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="incentives"
          defaultMessage="Incentives"
          description="Incentives"
        />
      </th>
      {renderCarIncentives}
      <td />
    </tr>
  )

  let renderPurchaseMethodCashRows = (
    <table className="table table-bordered">
      {renderTableHead}
      <tbody>
        {renderPurchaseMethodRow}
        {renderMSRPRow}
        {renderSalesTaxRow}
        {renderSalesTaxDollarsRow}
        {renderIncentivesRow}
        {resaleValueRow}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.totalVehicleCost"
              defaultMessage="Total Vehicle Cost"
              description="Total Vehicle Cost"
            />
          </th>
          {renderTotalCarCost}
          <td>
            <FormattedMessage 
              id="ev.showSources.cashFormula"
              defaultMessage="Total Vehicle Cost"
              description="Total Vehicle Cost"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );

  let renderDownPaymentRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="pricePanels.downPayment"
          defaultMessage="Down Payment"
          description="Down Payment"
        />
      </th>
      {renderCarDownPayment}
      <td>
        <FormattedMessage 
          id="ev.showSources.assumption"
          defaultMessage="Assumption"
          description="Assumption"
        /> {DOWN_PAYMENT.valueAsFormatted}</td>
    </tr>
  )

  let renderMonthsOfOwnershipRow = (
    <tr>
      <th scope="row">
        <FormattedMessage 
          id="ev.showSources.totalMonthlyPayments"
          defaultMessage="Monthly Payments"
          description="Monthly Payments"
        />
      </th>
      {renderCarTotalMonthlyPayments}
      <td>
        <FormattedMessage 
          id="ev.showSources.totalMonthlyPaymentsSource"
          defaultMessage="Monthly Payment * Months of Ownership"
          description="Total Monthly Payments Source"
        />
      </td>
    </tr>
  )

  let renderLifetimeMilesRow = (
    <tr>
      <th scope="row">
        {process.env.REACT_APP_METRIC_SYSTEM ? <FormattedMessage
          id="ev.showSources.lifetimeKilometers"
          defaultMessage="Lifetime Kilometers"
          description="Lifetime Kilometers"
        /> : <FormattedMessage
          id="ev.showSources.lifetimeMiles"
          defaultMessage="Lifetime Miles"
          description="Lifetime Miles"
        />}
      </th>
      <td colSpan={cars.length}>{FormatAsThousands(lifetimeMiles)}</td>
      <td>
        <FormattedMessage 
          id="ev.cardetails.calc.userInput"
          defaultMessage="User Input"
          description="User Input"
        />
      </td>
    </tr>
  )

  let renderPurchaseMethodLoanRows = (
    <table className="table table-bordered">
      {renderTableHead}
      <tbody>
        {renderPurchaseMethodRow}
        {renderMSRPRow}
        {renderSalesTaxRow}
        {renderDownPaymentRow}
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.loanAmount"
              defaultMessage="Loan Amount"
              description="Loan Amount"
            />
          </th>
          {renderCarLoanAmount}
          <td>
            <FormattedMessage 
              id="ev.showSources.loanFormula"
              defaultMessage="MSRP * (1 + Sales Tax) - Down Payment"
              description="Loan Formula"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.monthlyPayments"
              defaultMessage="Monthly Payments"
              description="Monthly Payments"
            />
          </th>
          {renderCarMonthlyPayments}
          <td>
            <FormattedMessage 
              id="ev.showSources.monthlyPaymentsSource"
              defaultMessage="Financial function of Loan Amount, Interest Rate, and Months of Ownership"
              description="Monthly Payments Source"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.monthsOfOwnership"
              defaultMessage="Months of Ownership"
              description="Months of Ownership"
            />
          </th>
          <td colSpan={cars.length}>{FormatAsThousands(monthsOfOwnership)}</td>
          <td>
            <FormattedMessage 
              id="ev.cardetails.calc.userInput"
              defaultMessage="User Input"
              description="User Input"
            />
          </td>
        </tr>
        {renderMonthsOfOwnershipRow}
        {renderIncentivesRow}
        {resaleValueRow}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.totalVehicleCost"
              defaultMessage="Total Vehicle Cost"
              description="Total Vehicle Cost"
            />
          </th>
          {renderTotalCarCost}
          <td>
            <FormattedMessage 
              id="ev.showSources.totalLoanFormula"
              defaultMessage="Down Payment + Total Monthly Payments - Incentives - Resale Value"
              description="Loan Formula"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );

  let renderPurchaseMethodLeaseRows = (
    <table className="table table-bordered">
      {renderTableHead}
      <tbody>
        {renderPurchaseMethodRow}
        {renderMSRPRow}
        {renderSalesTaxRow}
        {renderDownPaymentRow}
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.capitalizedIncentives"
              defaultMessage="Capitalized Incentives"
              description="Capitalized Incentives"
            />
          </th>
          {renderCarIncentives}
          <td>
            <FormattedMessage 
              id="ev.showSources.capitalizedIncentivesSource"
              defaultMessage="Incentives capitalized within the lease"
              description="Capitalized Incentives Source"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.capitalizedCost"
              defaultMessage="Capitalized Cost"
              description="Capitalized Cost"
            />
          </th>
          {renderCarCapitalizedCost}
          <td>
            <FormattedMessage 
              id="ev.showSources.capitalizedCostSource"
              defaultMessage="MSRP - Down Payment - Capitalized Incentives"
              description="Capitalized Cost"
            />
          </td>
        </tr>
        {resaleValueRow}
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.monthsOfOwnership"
              defaultMessage="Months of Ownership"
              description="Months of Ownership"
            />
          </th>
          <td colSpan={cars.length}>{FormatAsThousands(monthsOfOwnership)}</td>
          <td>
            <FormattedMessage 
              id="ev.cardetails.calc.userInput"
              defaultMessage="User Input"
              description="User Input"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.monthlyDepreciationCost"
              defaultMessage="Monthly Depreciation Cost"
              description="Monthly Depreciation Cost"
            />
          </th>
            {renderCarMonthlyDepreciation}
          <td>
            <FormattedMessage 
                id="ev.showSources.monthlyDepreciationCostSource"
                defaultMessage="(Capitalized Cost - Resalve Value) / Months of Ownership"
                description="Monthly Depreciation Cost Source"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.moneyFactor"
              defaultMessage="Money Factor"
              description="Money Factor"
            />
          </th>
          <td colSpan={cars.length}>
            {VehicleCost.moneyFactor(interestRateAsBasisPoints).toFixed(5)}
          </td>
          <td>
            <FormattedMessage 
              id="ev.showSources.moneyFactorSource"
              defaultMessage="Equivalent to user input of {interestRateAsBasisPoints}"
              description="Money Factor Source"
              values= {{
                interestRateAsBasisPoints: FormatAsPercent(interestRateAsBasisPoints / 100)
              }}
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.monthlyFinancingCost"
              defaultMessage="Monthly Financing Cost"
              description="Monthly Financing Cost"
            />
          </th>
            {renderCarMonthlyFinancingCost}
          <td>
            <FormattedMessage 
              id="ev.showSources.monthlyFinancingCostSource"
              defaultMessage="(Capitalized Cost + Resale Value) * Money Factor"
              description="Monthly Financing Cost Source"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.monthlyLeasePayment"
              defaultMessage="Monthly Lease Payment"
              description="Monthly Lease Payment"
            />
          </th>
            {renderCarMonthlyLeasePayments}
          <td>
            <FormattedMessage 
              id="ev.showSources.monthlyLeasePaymentSource"
              defaultMessage="(Monthly Depreciation Cost + Monthly Financing Cost) * (1 + Sales Tax)"
              description="Monthly Lease Payment Source"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.firstLeasePayment"
              defaultMessage="First Lease Payment"
              description="First Lease Payment"
            />
          </th>
            {renderCarFirstLeasePayment}
          <td>
            <FormattedMessage 
              id="ev.showSources.firstLeasePaymentSource"
              defaultMessage="Down Payment + Monthly Lease Payment"
              description="First Lease Payment Source"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.nonCapLeaseIncentives"
              defaultMessage="Non-capitalized Lease Incentives"
              description="Non-capitalized Lease Incentives"
            />
          </th>
            {renderCarNonCapitalizedLeaseIncentives}
          <td>
            <FormattedMessage 
              id="ev.showSources.nonCapLeaseIncentivesSource"
              defaultMessage="Incentives not captured in capitalized lease cost"
              description="Non-capitalized Lease Incentives Source"
            />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="row">
            <FormattedMessage 
              id="ev.showSources.totalVehicleCost"
              defaultMessage="Total Vehicle Cost"
              description="Total Vehicle Cost"
            />
          </th>
            {renderTotalCarCost}
          <td>
            <FormattedMessage 
              id="ev.showSources.leaseForumla"
              defaultMessage="First Lease Payment + (Months Of Ownership - 1) * (Monthly Lease Payment) - Non-capitalized Lease Incentives"
              description="Total Vehicle Lease Formula"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );

  return (
    <div className="CalcPanels">
      <div className="nav-fill nav">
        <button
          className={activeTab === "1" ? "active btn" : "btn"}
          onClick={() => toggle("1")}
        >
          <span>
            <FormattedMessage 
              id="graph.costOfOwnership.descriptionRow.vehicle"
              defaultMessage="Vehicle"
              description="Vehicle"
            />
          </span>
        </button>

        <button
          className={activeTab === "2" ? "active btn" : "btn"}
          onClick={() => toggle("2")}
        >
          <span>
            <FormattedMessage 
              id="graph.costOfOwnership.descriptionRow.electricity"
              defaultMessage="Electricity"
              description="Electricity"
            />
          </span>
        </button>
        <button
          className={activeTab === "3" ? "active btn" : "btn"}
          onClick={() => toggle("3")}
        >
          <span>
            <FormattedMessage 
              id="graph.costOfOwnership.descriptionRow.gasoline"
              defaultMessage="Gasoline"
              description="Gasoline"
            />
          </span>
        </button>
        <button
          className={activeTab === "4" ? "active btn" : "btn"}
          onClick={() => toggle("4")}
        >
          <span>
            <FormattedMessage 
              id="graph.costOfOwnership.descriptionRow.maintenance"
              defaultMessage="Maintenance"
              description="Maintenance"
            />
          </span>
        </button>
        <button
          className={activeTab === "5" ? "active btn" : "btn"}
          onClick={() => toggle("5")}
        >
          <span>
            <FormattedMessage 
              id="graph.costOfOwnership.descriptionRow.insurance"
              defaultMessage="Insurance"
              description="Insurance"
            />
          </span>
        </button>
      </div>
      <TabContent activeTab={activeTab}>
        <br />
        <TabPane tabId="1">
          {purchaseMethod === "cash" && renderPurchaseMethodCashRows}
          {purchaseMethod === "loan" && renderPurchaseMethodLoanRows}
          {purchaseMethod === "lease" && renderPurchaseMethodLeaseRows}
        </TabPane>
        <TabPane tabId="2">
          <table className="table table-bordered">
            {renderTableHead}
            <tbody>
              <tr>
                <th scope="row">{ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title}</th>
                {renderElectricityRate}
                <td>
                  <FormattedMessage 
                    id="ev.showSources.electricityRate"
                    defaultMessage="Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}"
                  description="Compare Vehicle Text"
                    values= {{
                      website: 
                        <a 
                          href={process.env.REACT_APP_ELECTRICITY_RATE_URL}
                          target="_blank"
                          rel="noopener noreferrer"> 
                          {process.env.REACT_APP_SLIDE_SALES_TAX ? intl.formatMessage({ id: "Energy Information Administration", defaultMessage: "Energy Information Administration" }) : intl.formatMessage({ id: "here", defaultMessage: "here" })}
                        </a>
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.kilometerVehicleKWh"
                      defaultMessage="Vehicle's kWh per 100 km"
                      description="Vehicle's kWh per 100 km"
                    /> :
                    <FormattedMessage
                      id="ev.showSources.vehicleKWh"
                      defaultMessage="Vehicle's kWh per 100 mi"
                      description="Vehicle's kWh per 100 mi"
                    />}
                  
                </th>
                {renderKWHPerHundredMiles}
                <td>
                  <FormattedMessage 
                    id="ev.showSources.EPAEfficiency"
                    defaultMessage="EPA Efficiency Rating"
                    description="EPA Efficiency Rating"
                  />
                </td>
              </tr>
              {process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING ? 
                <tr>
                  <th scope="row">
                    <FormattedMessage 
                      id="ev.showSources.estimatedCostOfPublicCharging"
                      defaultMessage="Estimated Cost of Public Charging"
                      description="Estimated Cost of Public Charging"
                    />
                  </th>
                    {renderEstimatedCostOfPublicCharging}
                  <td>
                    <FormattedMessage 
                      id="ev.cardetails.calc.userInput"
                      defaultMessage="User Input"
                      description="User Input"
                    />
                  </td>
                </tr>
                :
                null
              }
              {process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING ? 
                <tr>
                  <th scope="row">
                    <FormattedMessage 
                      id="ev.showSources.mixPublicPrivateCharging"
                      defaultMessage="Mix Public/Private Charging"
                      description="Mix Public/Private Charging"
                    />
                  </th>
                  {renderMixPublicPrivateCharging}
                  <td>
                    <FormattedMessage 
                      id="ev.cardetails.calc.userInput"
                      defaultMessage="User Input"
                      description="User Input"
                    />
                  </td>
                </tr>
                :
                null
              }
              <tr>
                <th scope="row"> 
                {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.dollarPerKilometerDriven"
                      defaultMessage="$/100 km driven"
                      description="Dollar Per Kilometer Driven"
                    /> :
                    <FormattedMessage
                      id="ev.showSources.dollarPerMileDriven"
                      defaultMessage="$/100 mi driven"
                      description="Dollar Per Mile Driven"
                    />}
                </th>
                {renderDollarPerHundredMiles}
                <td>
                  {process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING && process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING ?
                  process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage 
                        id="ev.showSources.dollarPerKilometerDrivenPublicCharging"
                    defaultMessage="(Estimated Cost of Public Charging * Mix Public/Private Charging + Electricity Rate * (1 - Mix Public/Private Charging)) * Vehicle kWh per 100 kilometer"
                    description="Dollar Per Kilometer Driven Source"
                  /> :
                  <FormattedMessage 
                    id="ev.showSources.dollarPerMileDrivenPublicCharging"
                    defaultMessage="(Estimated Cost of Public Charging * Mix Public/Private Charging + Electricity Rate * (1 - Mix Public/Private Charging)) * Vehicle kWh per 100 mi"
                    description="Dollar Per Mile Driven Source"
                  />
                  :
                  process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.dollarPerKilometerDrivenSource"
                      defaultMessage="Electricity Cost * Vehicle's kWh per 100 km"
                      description="Dollar Per Kilometer Driven Source"
                    /> :  
                      <FormattedMessage
                        id="ev.showSources.dollarPerMileDrivenSource"
                        defaultMessage="Electricity Cost * Vehicle's kWh per 100 mi"
                        description="Dollar Per Mile Driven Source"
                      />
                  
                  }
                </td>
              </tr>
              {renderLifetimeMilesRow}
              {renderPHEVFuelSplitAdjustmentRow}
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.totalElectricityCost"
                    defaultMessage="Total Electricity Cost"
                    description="Total Electricity Cost"
                  />
                </th>
                  {renderTotalElectricityCost}
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                      id="ev.showSources.totalKilometerElectricityCostSource"
                    defaultMessage="$/100 km driven * Lifetime Kilometers * {portionEVDriving}/ 100"
                    description="Total Electricity Cost Source"
                    values={{
                      portionEVDriving: isTherePHEV && intl.formatMessage({ id: "ev.cardetails.calc.portionElectric", defaultMessage: "Portion of Electric Driving" })
                    }}
                  /> : <FormattedMessage
                    id="ev.showSources.totalElectricityCostSource"
                    defaultMessage="$/100 mi driven * Lifetime Miles * {portionEVDriving}/ 100"
                    description="Total Electricity Cost Source"
                    values={{
                      portionEVDriving: isTherePHEV && intl.formatMessage({ id: "ev.cardetails.calc.portionElectric", defaultMessage: "Portion of Electric Driving" })
                    }}
                  />}
                </td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
        <TabPane tabId="3">
          <table className="table table-bordered">
            {renderTableHead}
            <tbody>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.gasolineCost"
                    defaultMessage="Gasoline Cost"
                    description="Gasoline Cost"
                  />
                </th>
                {renderGasPrice}
                <td>
                  <FormattedMessage 
                    id="ev.cardetails.calc.userInput"
                    defaultMessage="User Input"
                    description="User Input"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.vehiclelPerKm"
                      defaultMessage="Vehicle's LPerKm (liters per kilometer)"
                      description="Vehicle's LPerKm (liters per kilometer)"
                    /> :
                  <FormattedMessage
                    id="ev.showSources.vehicleMPG"
                    defaultMessage="Vehicle's MPG (miles per gallon)"
                    description="Vehicle's MPG (miles per gallon)"
                  />}
                </th>
                {renderMPG}
                <td />
              </tr>
              {renderLifetimeMilesRow}
              {renderPHEVFuelSplitAdjustmentRow}
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.totalGasolineCost"
                    defaultMessage="Total Gasoline Cost"
                    description="Total Gasoline Cost"
                  />
                </th>
                {renderGasolineCost}
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.totalKilometerGasolineCostSource"
                      defaultMessage="Gasoline Cost * Lifetime Kilometers *  {portionEVDriving}/ lPerKm"
                      description="Total Gasoline Cost Source"
                      values={{
                        portionEVDriving: isTherePHEV && " (1 - Portion of Electric Driving) "
                      }}
                    /> :
                    <FormattedMessage
                      id="ev.showSources.totalGasolineCostSource"
                      defaultMessage="Gasoline Cost * Lifetime Miles *  {portionEVDriving}/ MPG"
                      description="Total Gasoline Cost Source"
                      values={{
                        portionEVDriving: isTherePHEV && " (1 - Portion of Electric Driving) "
                      }}
                    />}
                </td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
        <TabPane tabId="4">
          <table className="table table-bordered">
            {renderTableHead}
            <tbody>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="vehicle.msrp"
                    defaultMessage="MSRP"
                    description="MSRP"
                  />
                </th>
                {renderCarMSRP}
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.showSources.maintenancePerKilometer"
                    defaultMessage="Maintenance per kilometer for gasoline"
                    description="Maintenance per kilometer for gasoline"
                  /> :
                  <FormattedMessage
                    id="ev.showSources.maintenancePerMile"
                    defaultMessage="Maintenance per mile for gasoline"
                    description="Maintenance per mile for gasoline"
                  />}
                </th>
                {renderMaintenanceForGas}
                <td>
                  <FormattedMessage 
                    id="ev.showSources.maintenancePerMileSource"
                    defaultMessage="Extrapolation of average maintenance cost of {avgMaintenanceCost}, driven
                    by vehicle MSRP in relation with average MSRP of {averageMSRP}"
                    description="Maintenance per mile Source"
                    values= {{
                      avgMaintenanceCost: AVERAGE_MAINTENANCE_COST_PER_MILE.valueAsFormatted,
                      averageMSRP: AVERAGE_MSRP.valueAsFormatted
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.maintenanceCostReduction"
                    defaultMessage="EV Maintenance Cost Reduction"
                    description="EV Maintenance Cost Reduction"
                  />
                </th>
                {renderEVMaintenance}
                <td>
                  <FormattedMessage 
                    id="ev.showSources.maintenanceCostReductionSource"
                    defaultMessage="{website}"
                    description="Compare Vehicle Text"
                    values= {{
                      website: 
                        <a 
                          href={ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        > 
                        <FormattedMessage 
                          id="ev.showSources.maintenanceCostReport"
                          defaultMessage="Electric Vehicle Ownership Costs - Consumer Reports"
                          description="Electric Vehicle Ownership Costs - Consumer Reports"
                        />                            
                        </a>
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.showSources.maintenancePerKilometerElectricity"
                    defaultMessage="Maintenance per kilometer for electricity"
                    description="Maintenance per kilometer for electricity"
                  />: 
                  <FormattedMessage
                    id="ev.showSources.maintenancePerMileElectricity"
                    defaultMessage="Maintenance per mile for electricity"
                    description="Maintenance per mile for electricity"
                  />}
                </th>
                {renderMaintenanceForElectricity}
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.maintenancePerKilometerElectricitySource"
                      defaultMessage="Maintenance per kilometer for gasoline * EV Maintenance Reduction Factor"
                      description="Maintenance per kilometer for electricity Source"
                    />: 
                    <FormattedMessage
                      id="ev.showSources.maintenancePerMileElectricitySource"
                      defaultMessage="Maintenance per mile for gasoline * EV Maintenance Reduction Factor"
                      description="Maintenance per mile for electricity Source"
                    />}
                </td>
              </tr>
              {renderPHEVFuelSplitAdjustmentRow}
              {isTherePHEV && (
                <tr>
                  <th scope="row">
                    {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.blendedMaintenanceCostPerKilometer"
                      defaultMessage="Blended maintenance cost per kilometer"
                      description="Blended Maintenance Cost Per Kilometer"
                    />:
                    <FormattedMessage
                      id="ev.showSources.blendedMaintenanceCostPerMile"
                      defaultMessage="Blended maintenance cost per mile"
                      description="Blended Maintenance Cost Per Mile"
                    />}
                  </th>
                  {renderBlendedMaintenance}
                  <td>
                    {process.env.REACT_APP_METRIC_SYSTEM ? 
                    <FormattedMessage
                      id="ev.showSources.blendedMaintenanceCostPerKilometerSource"
                      defaultMessage="Portion of Electric Driving applied to Maintenance per kilometer for gasoline and for electricity"
                      description="Blended Maintenance Cost Per Kilometer Source"
                    />: 
                    <FormattedMessage
                      id="ev.showSources.blendedMaintenanceCostPerMileSource"
                      defaultMessage="Portion of Electric Driving applied to Maintenance per mile for gasoline and for electricity"
                      description="Blended Maintenance Cost Per Mile Source"
                    />}
                  </td>
                </tr>
              )}
            {renderLifetimeMilesRow}
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.totalMaintenanceCost"
                    defaultMessage="Total Maintenance Cost"
                    description="Total Maintenance Cost"
                  />
                </th>
                {renderTotalMaintenance}
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.showSources.totalKilometerMaintenanceCostSource"
                    defaultMessage="Maintenance cost per kilometer * Lifetime Kilometeres"
                    description="Total Maintenance Cost Source"
                  />:
                  <FormattedMessage
                    id="ev.showSources.totalMaintenanceCostSource"
                     defaultMessage="Maintenance cost per mile * Lifetime Miles"
                    description="Total Maintenance Cost Source"
                  />}
                  
                </td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
        <TabPane tabId="5">
          <table className="table table-bordered">
            {renderTableHead}
            <tbody>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="vehicle.msrp"
                    defaultMessage="MSRP"
                    description="MSRP"
                  />
                </th>
                {renderCarMSRP}
                <td />
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.averageInsurancePerYear"
                    defaultMessage="Average Insurance per Year"
                    description="Average Insurance per Year"
                  />
                </th>
                <td colSpan={cars.length}>
                  {process.env.REACT_APP_DYNAMIC_INSURANCE_COST ? `$${FormatAsThousands(MUNICIPALITY_INFO[userPrefs.get("municipality")].insurance)}` : AVERAGE_INSURANCE_COST_ANNUALLY.valueAsFormatted}
                </td>
                <td>
                  <FormattedMessage
                    id="ev.showSources.insuranceSource"
                    defaultMessage="Average Insurance Cost In "
                    description="Average Insurance Cost In "
                  />
                  <a 
                    href="https://www.insure.com/car-insurance/car-insurance-rates.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {zipcode === "88001" ? "New Mexico" : "Texas"}
                  </a>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.insurancePerYear"
                    defaultMessage="Insurance per year"
                    description="Insurance per year"
                  />
                </th>
                {renderInsurance}
                <td>
                  <FormattedMessage 
                    id="ev.showSources.insurancePerYearSource"
                    defaultMessage="Extrapolation of average insurance cost in {state}, driven by vehicle MSRP in relation with average MSRP of {msrp}"
                    description="Insurance per year"
                    values = {{
                      msrp: AVERAGE_MSRP.valueAsFormatted,
                      state: process.env.REACT_APP_DYNAMIC_INSURANCE_COST ? userPrefs.get("municipality") : process.env.REACT_APP_COMPANY_STATE_ABRV
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.yearsOfOwnership"
                    defaultMessage="Years of Ownership"
                    description="Years of Ownership"
                  />
                </th>
                <td colSpan={cars.length}>{FormatAsThousands(monthsOfOwnership / 12)}</td>
                <td>
                  <FormattedMessage 
                    id="ev.cardetails.calc.userInput"
                    defaultMessage="User Input"
                    description="User Input"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="ev.showSources.totalInsuranceCost"
                    defaultMessage="Total Insurance Cost"
                    description="Total Insurance Cost"
                  />
                </th>
                {renderTotalInsurance}
                <td>
                  <FormattedMessage 
                    id="ev.showSources.totalInsuranceCostSource"
                    defaultMessage="Insurance cost per year * Years of Ownership"
                    description="Total Insurance Cost Source"
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CalcPanels;

CalcPanels.propTypes = {
  cars: PropTypes.array,
};
