import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../../../components/LinkCard/LinkCard";

import { FormattedMessage } from "react-intl";

const HomepageBigPromise = ({ homePageBannerImage }) => {
  return (
    <>
      <section
        className="home-banner"
        style={{ backgroundImage: "url(" + homePageBannerImage + ")" }}
      >
        <div className="container">
          <h1>
            <FormattedMessage
              id="homeBannerTitle"
              defaultMessage="Driving toward a greener future."
              description="Home banner title"
            />
          </h1>
          <p>
            <FormattedMessage
              id="homeBannerSubtitle"
              defaultMessage="Your personal guide to EVs."
              description="Home banner subtitle"
            />
          </p>
          <a href="/vehicles" className="banner-button">
            <FormattedMessage
              id="homeBannerBtn"
              defaultMessage="Find Your Electric Vehicle"
              description="Home banner subtitle"
            />
          </a>
        </div>
      </section>
      <section className="link-card-section">
        <div className="container">
          <div className="box-container text">
            <h3>
              <FormattedMessage
                id="homeCardTitle"
                defaultMessage="Estimate and compare costs, discover savings, incentives & more."
                description="Home banner subtitle"
              />
            </h3>
            <p>
              <FormattedMessage
                id="homeCardSubtitle"
                defaultMessage="Brought to you by El Paso Electric."
                description="Home banner subtitle"
              />
            </p>
          </div>
          <div className="box-container">
            <LinkCard type="BROWSE_VEHICLES" />
          </div>
          <div className="box-container">
            <LinkCard type="DISCOVER_INCENTIVES" />
          </div>
          <div className="box-container">
            <LinkCard type="COMPARE_VEHICLES" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
};
