import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { FormattedMessage } from "react-intl";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="top">
        <div className="container">
          <div className="row">
            <div className="menu-column">
              <ul>
                <li>
                  <a
                    title="Company"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.epelectric.com/company"
                    className="black text-left avenir-black em-8 caps"
                  >
                    <FormattedMessage
                      id="ev.footer.company"
                      defaultMessage="Company"
                      description="Company"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/about-epe"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.aboutEpe"
                          defaultMessage="About EPE"
                          description="About EPE"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/careers"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.careers"
                          defaultMessage="Careers"
                          description="Careers"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://ir.epelectric.com/home/default.aspx"
                        className="avenir-roman em-9 external"
                      >
                        <FormattedMessage
                          id="ev.footer.investorRelations"
                          defaultMessage="Investor Relations"
                          description="Investor Relations"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/partners-vendors"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.partners"
                          defaultMessage="Partners &amp; Vendors"
                          description="Partners &amp; Vendors"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/economic-development"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.economicDevelopment"
                          defaultMessage="Economic Development"
                          description="Economic Development"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/transmission"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.transmission"
                          defaultMessage="Transmission"
                          description="Transmission"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/news"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.news"
                          defaultMessage="News"
                          description="News"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/branding/"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.branding"
                          defaultMessage="EPE Branding"
                          description="EPE Branding"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/regulatory"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.regulatory"
                          defaultMessage="Regulatory"
                          description="Regulatory"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/request-for-proposals"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.requestProposals"
                          defaultMessage="Request for Proposals"
                          description="Request for Proposals"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/projects"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.projects"
                          defaultMessage="Projects"
                          description="Projects"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/public-notices"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.publicNotices"
                          defaultMessage="Public Notices"
                          description="Public Notices"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/company/privacy-policy"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.privacyPolicy"
                          defaultMessage="Privacy Policy"
                          description="Privacy Policy"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-column">
              <ul>
                <li>
                  <a
                    title="Customers"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.epelectric.com/customers"
                    className="black text-left avenir-black em-8 caps"
                  >
                    <FormattedMessage
                      id="ev.footer.customers"
                      defaultMessage="Customers"
                      description="Customers"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/customers/residential"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.residential"
                          defaultMessage="Residential"
                          description="Residential"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/customers/business"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.business"
                          defaultMessage="Business"
                          description="Business"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://myaccount.epelectric.com/"
                        className="avenir-roman em-9 external"
                      >
                        <FormattedMessage
                          id="ev.footer.manageYourAccount"
                          defaultMessage="Manage Your Account"
                          description="Manage Your Account"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/customers/rates-and-regulations"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.ratesRegulations"
                          defaultMessage="Rates and Regulations"
                          description="Rates and Regulations"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/save-money-and-energy"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.saveMoneyEnergy"
                          defaultMessage="Save Money and Energy"
                          description="Save Money and Energy"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/bill-management-center"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.billManagementCenter"
                          defaultMessage="Bill Management Center"
                          description="Bill Management Center"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/epe-move-center"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.moveCenter"
                          defaultMessage="EPE Move Center"
                          description="EPE Move Center"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/bill-management-center/pay-online-or-by-phone/bill2pay"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.bill2Pay"
                          defaultMessage="Download the Bill2Pay App"
                          description="Download the Bill2Pay App"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/customers/residential/customer-service/residential-rates-and-information"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.residentialRates"
                          defaultMessage="Residential Rates and Regulations"
                          description="Residential Rates and Regulations"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/customers/business/customer-service/business-rates-and-information"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.businessRates"
                          defaultMessage="Business Rates and Regulations"
                          description="Business Rates and Regulations"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-column">
              <ul>
                <li>
                  <a
                    title="Environmental, Social &amp; Governance"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.epelectric.com/environmental-social-governance"
                    className="black text-left avenir-black em-8 caps"
                  >
                    <FormattedMessage
                      id="ev.footer.environmentalSocial"
                      defaultMessage="Environmental, Social &amp; Governance"
                      description="Environmental, Social &amp; Governance"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/environmental-social-governance/environmental"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.environmental"
                          defaultMessage="Environmental"
                          description="Environmental"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/environmental-social-governance/social"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.social"
                          defaultMessage="Social"
                          description="Social"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/environmental-social-governance/corporate-governance"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.corporateGovernance"
                          defaultMessage="Corporate Governance"
                          description="Corporate Governance"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-column">
              <ul>
                <li>
                  <a
                    title="Renewables &amp; Tech"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.epelectric.com/renewables-tech"
                    className="black text-left avenir-black em-8 caps"
                  >
                    <FormattedMessage
                      id="ev.footer.renewablesTech"
                      defaultMessage="Renewables &amp; Tech"
                      description="Renewables &amp; Tech"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/renewables-tech/solar-energy"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.solarEnergy"
                          defaultMessage="Solar Energy"
                          description="Solar Energy"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/renewables-tech/community-solar"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.communitySolar"
                          defaultMessage="Community Solar"
                          description="Community Solar"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/renewables-tech/electric-vehicles"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.electricVehicles"
                          defaultMessage="Electric Vehicles"
                          description="Electric Vehicles"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/renewables-tech/programs"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.programs"
                          defaultMessage="Programs"
                          description="Programs"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/renewables-tech/distributed-generation"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.distributedGeneration"
                          defaultMessage="Distributed Generation"
                          description="Distributed Generation"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/renewables-tech/helpful-links"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.helpfulLinks"
                          defaultMessage="Helpful Links"
                          description="Helpful Links"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-column">
              <ul>
                <li>
                  <a
                    title="Safety &amp; Reliability"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.epelectric.com/safety-reliability"
                    className="black text-left avenir-black em-8 caps"
                  >
                    <FormattedMessage
                      id="ev.footer.safetyReliability"
                      defaultMessage="Safety &amp; Reliability"
                      description="Safety &amp; Reliability"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/outage-center"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.outageCenter"
                          defaultMessage="Outage Center"
                          description="Outage Center"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/safety-reliability/drone-flight-notifications-utepwill-perform-close-quarter-aerial-inspections-on-the-rio-grande-powerplantboiler-7"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.droneFlight"
                          defaultMessage="Drone Flight Notifications - Culture Span
                        Marketing&nbsp;will be collecting aerial motion picture
                        footage of the POWER SIGN at the old Rio Grande
                        Facility."
                          description="Drone Flight Notifications - Culture Span
                        Marketing&nbsp;will be collecting aerial motion picture
                        footage of the POWER SIGN at the old Rio Grande
                        Facility."
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/safety-reliability/safety"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.safety"
                          defaultMessage="Safety"
                          description="Safety"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.epelectric.com/outage-center/outage-tips-and-more/reliability-information"
                        className="avenir-roman em-9"
                      >
                        <FormattedMessage
                          id="ev.footer.reliabilityInformation"
                          defaultMessage="Reliability Information"
                          description="Reliability Information"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-column">
              <ul>
                <li>
                  <a
                    title="Customer Newsletter"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.epelectric.com/customer-newsletter"
                    className="external black avenir-black em-8 text-left caps"
                  >
                    <FormattedMessage
                      id="ev.footer.customerNewsletter"
                      defaultMessage="Customer Newsletter"
                      description="Customer Newsletter"
                    />
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="footer-bottom" className="container-fluid">
          <div className="row">
            <div className="col-sm-12 remove-padding">
              <div className="bg-blue white avenir-black em-9 caps">
                <ul className="footer-quick-links">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.epelectric.com/contact-us"
                      title="Contact Us"
                    >
                      <FormattedMessage
                        id="ev.footer.contactUs"
                        defaultMessage="Contact Us"
                        description="Contact Us"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.epelectric.com/faqs"
                      title="FAQs"
                    >
                      <FormattedMessage
                        id="ev.footer.FAQs"
                        defaultMessage="FAQs"
                        description="FAQs"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.epelectric.com/customer-newsletter"
                      title="Newsletter"
                    >
                      <FormattedMessage
                        id="ev.footer.newsletter"
                        defaultMessage="Newsletter"
                        description="Newsletter"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.epelectric.com/company/news"
                      title="News"
                    >
                      <FormattedMessage
                        id="ev.footer.news"
                        defaultMessage="News"
                        description="News"
                      />
                    </a>
                  </li>
                </ul>
                <ul id="social-media" className="">
                  <li>
                    <a
                      className="external"
                      href="https://www.facebook.com/ElPasoElectric"
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="external"
                      href="https://twitter.com/elpasoelectric"
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="external"
                      href="https://www.youtube.com/myepe"
                      title="YouTube"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="external"
                      href="https://www.instagram.com/elpasoelectric/"
                      title="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="external"
                      href="https://www.linkedin.com/company/el-paso-electric-company"
                      title="LinkedIn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="disclaimer">
        <div className="container">
          <p>
            <FormattedMessage
              id="footerDisclaimer"
              defaultMessage="El Paso Electric (EPE) does not endorse, recommend, or promote any vehicle (electrified or otherwise) or dealership over another. Site visitors are encouraged to perform their own due diligence when selecting a vehicle, home charging station, or service provider. Please consult the vehicle’s manufacturer, incentive grantor, dealership, or public charging station operator with any specific questions or inquiries for that particular product or service. Vehicle specifications such as, but not limited to quotes of range and time to charge, are estimates and individual users’ experiences may vary. The vehicle pricing and specifications, incentive information, home charger details, and calculations in this tool are for educational purposes only and EPE does not guarantee the accuracy or completeness of any of the information provided. EPE rebates are subject to change and visitors should verify EPE’s rates for their particular service territory at"
              description="Home banner subtitle"
            />
            <a href="https://www.epelectric.com/customers/rates-and-regulations">
              https://www.epelectric.com/customers/rates-and-regulations
            </a>
            .
          </p>
        </div>
      </div>

      <div className="bottom-bar">
        <div className="container">
          <div className="left">
            <p>
              &copy; 2022 El Paso Electric&nbsp;|&nbsp;
              <a href="/privacy-policy" title="Privacy Policy" className="neon">
                <FormattedMessage
                  id="ev.footer.privacyPolicy"
                  defaultMessage="Privacy Policy"
                  description="Privacy Policy"
                />
              </a>
              &nbsp;|&nbsp;
              <a href="/terms-of-use" title="Terms of Use" className="neon">
                <FormattedMessage
                  id="ev.footer.termsUse"
                  defaultMessage="Terms of Use"
                  description="Terms of Use"
                />
              </a>
             
              {/* <a rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a> */}
            </p>
          </div>
          <div className="disclaimer_container" >
                  <DisclaimerComponent clientName="zappynobackground" 
                  imageStyle={{marginLeft:"200px", marginTop:"-7px"}}
                  textStyle={{fontFamily:"Open Sans,Helvetica, sans-serif", fontSize:"13px"}}  
                  wraperStyle={{position:"0px"}}
                  />

                  {/* <DisclaimerComponent clientName="zappynobackground" textStyle={{fontFamily:"TT Norms Pro", fontSize:"14px"}} imageStyle={{marginLeft:"385px"}} wrapperStyle={{ 
    marginTop: '-35px'
  }}/> */}

                </div>
          {/* <div className="right">
            <p>
              Powered by{" "}
              <img src={ZappyRidewhite} alt="ZappyRide logomark footer" style={{maxWidth: "180px", padding:"10px 3px", marginLeft: "10px", position: "relative", top: "-1px"}} />
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
