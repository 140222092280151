import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

const Header = ({ page, language, changeLanguage }) => {
  const userPrefs = useContext(UserPrefsContext);

  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  const updateLanguage = (e, lang) => {
    e.preventDefault();
    changeLanguage(lang);
  };

  const zipcode = userPrefs.get("zipcode");

  const handleChangeZip = (zip) => {
    switch (zip) {
      case "TX":
        userPrefs.set({
          zipcode: "79901",
          workingZipcode: "79901",
          salesTax: ".0625",
          electricityRate: ".13736334",
          assumptionsLink:
            "https://www.epelectric.com/customers/rates-and-regulations/residential-rates-and-information/texas-rate-tariffs-rules-and-regulations/texas-rate-tariffs",
        });
        break;

      case "NM":
        userPrefs.set({
          zipcode: "88001",
          workingZipcode: "88001",
          salesTax: ".04",
          electricityRate: ".10837085",
          assumptionsLink:
            "https://www.epelectric.com/customers/rates-and-regulations/residential-rates-and-information/new-mexico-rate-tariffs-rules-and-regulations/new-mexico-rate-tariffs",
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="Header">
      <div className="top-bar">
        <div className="container">
          <div className="toggle">
            <a
              href="#TX"
              onClick={() => handleChangeZip("TX")}
              className={zipcode === "79901" ? "active" : ""}
            >
              Texas
            </a>
            &nbsp;|&nbsp;
            <a
              href="#NM"
              onClick={() => handleChangeZip("NM")}
              className={zipcode === "88001" ? "active" : ""}
            >
              New Mexico
            </a>
          </div>
          <div className="toggle">
            <a
              href="/"
              className={language === "EN" ? "active" : ""}
              onClick={(e) => updateLanguage(e, "EN")}
            >
              English
            </a>
            &nbsp;|&nbsp;
            <a
              href="/"
              className={language === "ES" ? "active" : ""}
              onClick={(e) => updateLanguage(e, "ES")}
            >
              Espa&ntilde;ol
            </a>
          </div>
        </div>
      </div>
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="https://www.epelectric.com/renewables-tech/electric-vehicles"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid header-img"
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>
                      <FormattedMessage
                        id="home"
                        defaultMessage="Home"
                        description="Home Header"
                      />
                    </span>
                  </Link>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/used-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "map" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="maps"
                      defaultMessage="Maps"
                      description="Maps Header"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  {MAP_TABS.filter(tab => tab.isEnabled).map(tab => {
                    return (
                      <DropdownItem
                        key={`nav-item-${tab.id}`}
                        title={page === "map" ? "Active Page" : null}
                        tag={NavLink}
                        to={tab.url}
                      >
                        {tab.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown> */}
              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={
                      page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK
                        ? "Active Page"
                        : null
                    }
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>
                      <FormattedMessage
                        id="FAQ"
                        defaultMessage="FAQ"
                        description="FAQ Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "events" ? "Active Page" : null}
                    to="/events"
                  >
                    <span>
                      <FormattedMessage
                        id="events"
                        defaultMessage="Events"
                        description="Events Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
