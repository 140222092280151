import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { FormattedMessage, useIntl } from "react-intl";

const SlideMilesDrivenDaily = ({
  id = "miles-driven-daily-range",
  label = (
    <FormattedMessage
      id="evs.roundTripCommute"
      defaultMessage="Roundtrip Commute"
      description="Roundtrip Commute"
    />
  ),
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const descriptionFn = (val) => {
    return `${FormatAsThousands(val)} ${
      intl.formatMessage
        ? process.env.REACT_APP_METRIC_SYSTEM
          ? intl.formatMessage({
              id: "vehicle.kilometersCapital",
              defaultMessage: "Kilometers",
            })
          : intl.formatMessage({
              id: "vehicle.milesCapital",
              defaultMessage: "Miles",
            })
        : "Miles"
    }`;
  };

  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenDaily")}
      label={label}
      rangeMin={1}
      rangeMax={150}
      rangeStep={1}
      description={descriptionFn}
      ariaControls="electric-vehicles-catalog"
      handler={(e) => userPrefs.set({ milesDrivenDaily: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenDaily;

SlideMilesDrivenDaily.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
};
