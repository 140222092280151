import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "./../../utils/Helpers/Format";
import { useIntl } from "react-intl";
import GaTracker from "./../../utils/GaTracker/GaTracker";

const IncentiveCard = (props) => {
  const intl = useIntl();
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility;

  if (eligibility === "ineligible") {
    return null;
  }

  let incentiveTypes = {
    "Bill Credit": intl.formatMessage
      ? intl.formatMessage({ id: "billCredit", defaultMessage: "Bill Credit" })
      : "Bill Credit",
    "Charging Discount": intl.formatMessage
      ? intl.formatMessage({
          id: "chargingDiscount",
          defaultMessage: "Charging Discount",
        })
      : "Charging Discount",
    "Curbside Charging": intl.formatMessage
      ? intl.formatMessage({
          id: "curbsideCharging",
          defaultMessage: "Curbside Charging",
        })
      : "Curbside Charging",
    "Discounted Electric Rate": intl.formatMessage
      ? intl.formatMessage({
          id: "discountedElectricRate",
          defaultMessage: "Discounted Electric Rate",
        })
      : "Discounted Electric Rate",
    "Fee Increase": intl.formatMessage
      ? intl.formatMessage({
          id: "feeIncrease",
          defaultMessage: "Fee Increase",
        })
      : "Fee Increase",
    Financing: intl.formatMessage
      ? intl.formatMessage({ id: "financing", defaultMessage: "Financing" })
      : "Financing",
    "Free Equipment": intl.formatMessage
      ? intl.formatMessage({
          id: "freeEquipment",
          defaultMessage: "Free Equipment",
        })
      : "Free Equipment",
    "HOV Lane": intl.formatMessage
      ? intl.formatMessage({ id: "hovLane", defaultMessage: "HOV Lane" })
      : "HOV Lane",
    "Insurance Discount": intl.formatMessage
      ? intl.formatMessage({
          id: "insuranceDiscount",
          defaultMessage: "Insurance Discount",
        })
      : "Insurance Discount",
    "Parking Exemption": intl.formatMessage
      ? intl.formatMessage({
          id: "parkingExemption",
          defaultMessage: "Parking Exemption",
        })
      : "Parking Exemption",
    "Public Charging": intl.formatMessage
      ? intl.formatMessage({
          id: "publicCharging",
          defaultMessage: "Public Charging",
        })
      : "Public Charging",
    Rebate: intl.formatMessage
      ? intl.formatMessage({ id: "rebate", defaultMessage: "Rebate" })
      : "Rebate",
    "Registration Fee": intl.formatMessage
      ? intl.formatMessage({
          id: "registrationFee",
          defaultMessage: "Registration Fee",
        })
      : "Registration Fee",
    "Tax Credit": intl.formatMessage
      ? intl.formatMessage({ id: "taxCredit", defaultMessage: "Tax Credit" })
      : "Tax Credit",
    "Tax Exemption": intl.formatMessage
      ? intl.formatMessage({
          id: "taxExemption",
          defaultMessage: "Tax Exemption",
        })
      : "Tax Exemption",
    "Toll Discount": intl.formatMessage
      ? intl.formatMessage({
          id: "tollDiscount",
          defaultMessage: "Toll Discount",
        })
      : "Toll Discount",
    "Vehicle Retirement": intl.formatMessage
      ? intl.formatMessage({
          id: "vehicleRetirement",
          defaultMessage: "Vehicle Retirement",
        })
      : "Vehicle Retirement",
  };

  let name = incentive.grantor + " " + incentive.name || "";
  let type = incentive.type;
  let description = incentive.description || "";

  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  const handleClick = () => {
    GaTracker.trackEvent({
      category: "Incentives",
      action: "Clicked on Incentive Card",
      label: `${incentive.name}`,
    });
  };

  let renderCard = incentive ? (
    <a
      className="evc-card IncentiveCard"
      href={incentive.details_url}
      target="_blank"
      rel="noopener noreferrer"
       onClick={handleClick}
    >
      <div className="IncentiveCardBody">
        <h5 className="card-type">{incentiveTypes[type]}</h5>
        <p className="h1 card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="h6">{name}</p>
      </div>
      <hr />
      <div className="IncentiveCardBottom">{description}</div>
    </a>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool,
};
