import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const SlidePortionPublicCharging = ({
  id = "portion-public-charging-range",
  ...rest
}) => {
  const intl = useIntl()
  const label = intl.formatMessage ? intl.formatMessage({ id: "ev.publicPrivateCharging", defaultMessage: "Mix public/private charging"}) : "Mix public/private charging"  
  const userPrefs = useContext(UserPrefsContext);
  const description = val => {
    return `${val}% ${intl.formatMessage ? intl.formatMessage({ id: "ev.public", defaultMessage: "Public"}) : "Public"  }`;
  };

  return (
    <Range
      id={id}
      value={userPrefs.get("publicChargingPercentage")}
      label={label}
      rangeMin={0}
      rangeMax={100}
      rangeStep={10}
      description={description}
      ariaControls="electric-vehicles-catalog"
      handler={e => userPrefs.set({ publicChargingPercentage: e.target.value })}
      {...rest}
    />
  );
};

export default SlidePortionPublicCharging;

SlidePortionPublicCharging.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
