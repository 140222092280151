import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData"
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";
import {FormattedMessage} from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData([
    { field: "grantor", value: "Federal", count: 1 },
    { field: "grantor_type", value: "State", count: 2 },
    { field: "grantor_type", value: "Power Supplier", count: 3 }
  ], incentives)

  return (
    <section id="HomepageIncentives">
      <div className="container">
        <div className="row">
          <div className="title">
              <h3>
                <FormattedMessage 
                    id="homepage.incentives.title"
                    defaultMessage="Explore potential EV incentives and tax credits"
                    description="Homepage Incentives Title"
                />
              </h3>

              <div className="contain-text">
                <p>
                <FormattedMessage 
                    id="homepage.incentives.subTitle"
                    defaultMessage="See how much you could save getting behind the wheel of an EV,
                    whether you are buying or leasing. Incentives are personalized for where you live."
                    description="Homepage Incentives Sub Title"
                    values= {{
                      lineBreak: <br />
                    }}
                  />
                </p>
              </div>
              <Link to="/incentives" style={{margin:"12px"}} className="btn btn-ae" role="button">
                <FormattedMessage 
                    id="homepage.incentives.exporeIncentives"
                    defaultMessage="EXPLORE INCENTIVES"
                    description="Explore Incentives Button"
                  />
              </Link>
            </div>
          </div>
          <IncentiveCatalog incentives={incentives} category="all" />
      </div>
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
